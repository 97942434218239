import 'pure-react-carousel/dist/react-carousel.es.css'
import '../../styles/components/carousel.scss'

import { throttle } from 'lodash'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import React, { useMemo, useRef, useState, useEffect } from 'react'

import { win } from '../../utils/monads'
import { CarouselControllers } from './carousel-controllers'

interface CarouselProps {
  slides: React.ReactNode[]
  visibleSlides?: number
  step?: number
  interval?: number
}

export const Carousel: React.FC<CarouselProps> = ({
  slides,
  visibleSlides = 1,
  step = 1,
  interval = 8000,
}) => {
  const slidesContainerRef = useRef<HTMLDivElement>(null)
  const slideRef = useRef<any>(null)

  const [dimensions, setDimensions] = useState<{
    width: number
    height: number
  }>({ width: 0, height: 0 })

  const updateDimensions = useMemo(
    () =>
      throttle(() => {
        const container = slideRef.current

        if (!container) {
          return
        }

        const { width, height } = container.getBoundingClientRect()

        setDimensions({ width, height })
      }, 0),
    []
  )

  useEffect(() => {
    updateDimensions()
    win.addEventListener('resize', updateDimensions)
    return () => win.removeEventListener('resize', updateDimensions)
  }, [updateDimensions])

  return (
    <div className="carousel">
      <CarouselProvider
        naturalSlideWidth={120}
        naturalSlideHeight={50}
        totalSlides={slides?.length}
        step={step}
        visibleSlides={visibleSlides}
        dragEnabled
        infinite
        interval={interval}
        isPlaying
        lockOnWindowScroll={false}
      >
        <div className="carousel__container" ref={slidesContainerRef}>
          <Slider>
            {slides?.map((slide, index) => (
              <Slide
                key={index}
                index={index}
                style={{ paddingBottom: dimensions.height + 20 }}
              >
                <div
                  onLoad={updateDimensions}
                  ref={slideRef}
                  style={{
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                    boxSizing: 'border-box',
                  }}
                >
                  {slide}
                </div>
              </Slide>
            ))}
          </Slider>
        </div>
        <CarouselControllers interval={interval} />
      </CarouselProvider>
    </div>
  )
}
