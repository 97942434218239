import React, { memo } from 'react'

import { CarouselNavigation } from './carousel-navigation'
import { useCarouselContext } from './context'

export interface CarouselControllersProps {
  interval?: number
}

export const CarouselControllers = memo<CarouselControllersProps>(
  ({ interval }) => {
    const {
      state: { totalSlides, visibleSlides },
    } = useCarouselContext()

    const shouldRenderControllers = totalSlides > visibleSlides

    return shouldRenderControllers ? (
      <CarouselNavigation interval={interval} />
    ) : null
  }
)
