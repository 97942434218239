import '../../styles/components/arrow-indicator.scss'

import React from 'react'

export const ArrowIndicator: React.FC = () => {
  return (
    <svg
      className="icon icon--indicator"
      width="15"
      height="19"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.2637 11.3684L7.63209 18L1.00051 11.3684" stroke="white" />
      <path d="M7.63184 18L7.63184 0" stroke="white" />
    </svg>
  )
}
