import type { CarouselStoreInterface } from 'pure-react-carousel'
import { CarouselContext } from 'pure-react-carousel'
import { useState, useContext, useEffect, useCallback } from 'react'

export const useCarouselContext = () => {
  const context = useContext(CarouselContext)
  const [ctx, setCtx] = useState<CarouselStoreInterface>(context)

  useEffect(() => {
    const unsub = context.subscribe(() => {
      setCtx({ ...context })
    })

    return unsub
  }, [context])

  const onNextSlide = useCallback(() => {
    const state = ctx.getStoreState()

    setTimeout(() => {
      ctx.setStoreState({
        isPlaying: false,
        currentSlide:
          state.currentSlide === state.totalSlides - 1
            ? state.currentSlide
            : state.currentSlide + 1,
      })
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPreviousSlide = useCallback(() => {
    const state = ctx.getStoreState()

    setTimeout(() => {
      ctx.setStoreState({
        isPlaying: false,
        currentSlide:
          state.currentSlide === 0
            ? state.currentSlide
            : state.currentSlide - 1,
      })
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setCurrentSlide = useCallback((index: number) => {
    setTimeout(() => {
      ctx.setStoreState({ currentSlide: index, isPlaying: false })
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ...ctx, onNextSlide, onPreviousSlide, setCurrentSlide }
}
